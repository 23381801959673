import { reactive } from 'vue'
import { usePWACachedFormations } from '@store/pwaCachedFormations.js'
import { HEADSET_ACTIONS, HEADSET_EVENTS } from './Headset'

export const HeadsetStreamEvents = {
    CLOSE: 'close',
}

export class HeadsetStream {
    /**
     * Singleton
     * @type {Map<any, any>}
     */
    static instances = new Map()

    /**
     @param {Headset} headset
     */
    constructor(headset) {
        this.headset = headset
        this.streaming = false
        this.state = reactive({
            pause: false,
            resolution: {
                width: 1280,
                height: 720,
            },
            micro: false,
            sound: false,
            pinned: false,
        })
        this.isFullScreen = false
        this.$component = null
        this.mediaStream = null

        /** @type HTMLVideoElement */
        this.videoPlayerElement = document.createElement('video')
        this.videoPlayerElement.setAttribute(
            'poster',
            'https://cdn.indiawealth.in/public/images/transparent-background-mini.png'
        )
        this.videoPlayerElement.dataset.serialNumber = headset.serialNumber

        this.eventBindCallBack = () => {}
        this.videosElements = []
        this.handleWSHeadsetDisconnection = () => {
            this.start().then()
        }

        return reactive(this)
    }

    get modulesState() {
        if (
            !Number.isFinite(this.headset?.currentFormation?.current_module) ||
            this.headset?.currentFormation?.current_module === undefined
        )
            return null
        else
            return {
                modules: this.headset.currentFormation.modules_playlist.map(
                    (e) => ({
                        number: e,
                    })
                ),
                module: this.formation
                    .getModuleCollection()
                    .getByNumber(this.headset.currentFormation.current_module),
                currentModule: this.headset.currentFormation.current_module,
            }
    }

    get formation() {
        /**
         * @type {{formations : FormationCollection}}
         */
        const store = usePWACachedFormations()
        return store.formations.getBySlug(this.headset.currentFormation.slug)
    }

    get attached() {
        return this.videosElements.length > 0
        //check if the videoPlayerElement is attached to the DOM
        //return this.videoPlayerElement.isConnected
    }

    get microphoneStream() {
        return window.microphoneStream || null
    }

    set microphoneStream(stream) {
        window.microphoneStream = stream
    }

    get emittingPeer() {
        return this.headset.emitingPeer
    }

    /**
     * @param headset
     * @returns {HeadsetStream}
     */
    static getInstance(headset) {
        if (!HeadsetStream.instances.has(headset.serialNumber)) {
            HeadsetStream.instances.set(
                headset.serialNumber,
                new HeadsetStream(headset)
            )
        }
        return HeadsetStream.instances.get(headset.serialNumber)
    }

    static broadcast(
        callable,
        streamsInstances = HeadsetStream.instances.filter((e) => e.attached),
        ...args
    ) {
        streamsInstances.forEach((instance) => callable(instance, ...args))
    }

    setEventBindCallback(callable) {
        this.eventBindCallBack = callable
    }

    resetState() {
        this.state.pause = false
        this.state.resolution = {
            width: 1280,
            height: 720,
        }
        this.state.micro = false
        this.state.sound = false
    }

    handle(stream) {
        this.mediaStream = stream
        this.videoPlayerElement.srcObject = stream
        this.videosElements.forEach((video) => {
            video.srcObject = stream
        })
        this.play()
    }

    /**
     * Play the stream if videoPlayerRef exist and MediaStream is set
     */
    play() {
        if (!this.mediaStream) return
        if (!this.attached) return

        this.videosElements.forEach((video) => {
            video.play()
        })
    }

    attach($component, videoContainerHTMLElement) {
        let clone = this.videoPlayerElement.cloneNode(true)
        clone.srcObject = this.mediaStream
        videoContainerHTMLElement.appendChild(clone)
        this.$component = $component
        this.setState({
            resolution: this.videoPlayerElement.getBoundingClientRect().height,
        })
        this.videosElements.push(clone)
        this.play()
    }

    /**
     *
     * @param $component
     * @param videoContainerHTMLElement {HTMLElement}
     */
    detach($component, videoContainerHTMLElement) {
        if (!videoContainerHTMLElement) return
        const containAVideoWithDataSerialNumber =
            videoContainerHTMLElement.querySelector(
                `[data-serial-number="${this.headset.serialNumber}"]`
            )

        if (videoContainerHTMLElement && containAVideoWithDataSerialNumber) {
            containAVideoWithDataSerialNumber.pause()
            videoContainerHTMLElement.removeChild(
                containAVideoWithDataSerialNumber
            )

            this.videosElements = this.videosElements.filter(
                (e) => e.isConnected
            )
        }
        this.$component = null
    }

    setState(state) {
        Object.assign(this.state, state)
    }

    async pause() {
        await this.headset.send({
            type: HEADSET_ACTIONS.PAUSE,
        })
    }

    async resume() {
        await this.headset.send({
            type: HEADSET_ACTIONS.RESUME,
        })
    }

    async start() {
        if (this.streaming) return
        this.headset.off(
            HEADSET_EVENTS.WS_CONNECTED,
            this.handleWSHeadsetDisconnection.bind(this)
        )
        this.headset.once(
            HEADSET_EVENTS.WS_CONNECTED,
            this.handleWSHeadsetDisconnection.bind(this)
        )
        if (!this.headset.isConnected) return
        this.streaming = true
        HeadsetStream.instances.set(this.headset.serialNumber, this)

        this.resetState()
        await this.headset.send({
            type: HEADSET_ACTIONS.START_STREAM,
        })
        await this.resize()

        this.emittingPeer.peer.on(
            `action:${HEADSET_ACTIONS.RESUME}`,
            this.handleTogglePauseResume.bind(this)
        )
        this.emittingPeer.peer.on(
            `action:${HEADSET_ACTIONS.PAUSE}`,
            this.handleTogglePauseResume.bind(this)
        )
    }

    async stop() {
        if (!this.streaming) {
            this.detach()
            return
        }
        this.streaming = false
        this.videoPlayerElement.srcObject = null
        this.headset.off(
            HEADSET_EVENTS.WS_CONNECTED,
            this.handleWSHeadsetDisconnection.bind(this)
        )

        if (this.emittingPeer.peer) {
            this.emittingPeer.peer.off(
                `action:${HEADSET_ACTIONS.RESUME}`,
                this.handleTogglePauseResume.bind(this)
            )
            this.emittingPeer.peer.off(
                `action:${HEADSET_ACTIONS.PAUSE}`,
                this.handleTogglePauseResume.bind(this)
            )
        }

        if (!this.headset.emitingPeer.isClosed())
            this.headset.send({
                type: HEADSET_ACTIONS.STOP_STREAM,
            })
        return
    }

    async setResolution(width = 1280, height = 720) {
        await this.headset.send({
            type: HEADSET_ACTIONS.SCREEN_RESOLUTION,
            resolution: {
                width,
                height,
            },
        })
        this.headset.emitingPeer.once(
            `action:${HEADSET_ACTIONS.SET_RESOLUTION}`,
            (data) => {
                this.setState({ resolution: data.resolution })
            }
        )
    }

    async setMicrophoneMute(mute) {
        let type = mute
            ? HEADSET_ACTIONS.MICRO_MUTE
            : HEADSET_ACTIONS.MICRO_UNMUTE

        if (!mute) {
            /* End all other microphone */
            this.broadcast((streamInstance) => {
                streamInstance.setMicrophoneMute(true)
            })
        }

        await this.headset.send({
            type,
        })

        this.emittingPeer.waitForResponse(`action:${type}`, (data) => {
            if (data.micro) {
                this.askForMicrophone().then((stream) => {
                    this.microphoneStream = stream
                    this.headset.emitingPeer.addStream(this.microphoneStream)
                })
            } else {
                if (this.microphoneStream) {
                    this.headset.emitingPeer?.removeStream(
                        this.microphoneStream
                    )
                    this.microphoneStream?.getTracks().forEach((track) => {
                        track.stop()
                    })
                    this.microphoneStream = null
                }
            }
            this.setState({ micro: data.micro })
        })
    }

    async setSoundMute(mute) {
        let type = mute
            ? HEADSET_ACTIONS.SOUND_MUTE
            : HEADSET_ACTIONS.SOUND_UNMUTE

        if (!mute) {
            /* Mute all others stream */
            this.broadcast((streamInstance) => {
                streamInstance.setSoundMute(true)
            })
        }

        await this.headset.send({
            type,
        })

        this.emittingPeer.waitForResponse(`action:${type}`, (data) => {
            this.setState({ sound: data.sound })
        })
    }

    askForMicrophone() {
        if (this.microphoneStream)
            return new Promise((resolve) => resolve(this.microphoneStream))
        return new Promise((resolve, reject) => {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    this.microphoneStream = stream
                    resolve(stream)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    async resize() {
        let { height, width } = this.videoPlayerElement.getBoundingClientRect()
        await this.setResolution(width, height)
    }
    toggleFullscreen() {
        if (this.isFullScreen) {
            this.$component.$el.classList.remove('mdm-stream-fullscreen')
            this.resize().then()
            this.broadcast((streamInstance) => {
                streamInstance.resize() //Ask for all other stream to resize to their needed resolution
            })
        } else {
            this.$component.$el.classList.add('mdm-stream-fullscreen')
            //Ask for all other stream to reduce their resolution (the headset have a minimum resolution on their side 144p)
            this.broadcast((streamInstance) => {
                streamInstance.setResolution(10, 10)
            })
            this.setResolution(window.screen.width, window.screen.height)
        }
        this.isFullScreen = !this.isFullScreen
        this.$component.isFullScreen = this.isFullScreen
    }

    getOtherStreams() {
        return Array.from(HeadsetStream.instances.values())
            .filter((e) => e.attached)
            .filter((i) => i.headset.serialNumber !== this.headset.serialNumber)
    }
    broadcast(callable, ...args) {
        let streamsInstances = this.getOtherStreams()

        HeadsetStream.broadcast(callable, streamsInstances, ...args)
    }

    async close() {
        console.trace('CLOSE STREAM')
        if (!this.attached) return false
        if (!this.headset.emitingPeer.isClosed())
            await Promise.all([
                this.setSoundMute(true),
                this.setMicrophoneMute(true),
            ])
        this.eventBindCallBack(HeadsetStreamEvents.CLOSE)
        await this.stop()
        this.headset.closeRTCConnection()
    }

    async destroy() {
        await this.close()
        this.streaming = false
        HeadsetStream.instances.delete(this.headset.serialNumber)
    }

    handleTogglePauseResume(data) {
        this.setState({ pause: data.pause })
    }

    togglePin() {
        let pinnedNewValue = !this.state.pinned
        if (pinnedNewValue) {
            let otherStreams = this.getOtherStreams()
            otherStreams.forEach((stream) => {
                stream.setState({ pinned: false })
            })
        }
        this.setState({ pinned: pinnedNewValue })
    }

    toggleMicro() {
        this.setMicrophoneMute(this.state.micro)
    }

    toggleVolume() {
        this.setSoundMute(this.state.sound)
    }

    togglePause() {
        if (this.state.pause) this.resume()
        else this.pause()
    }
}

globalThis.$headsetStream = HeadsetStream
