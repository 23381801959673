export function headsetGroupPatchInputs(companyId) {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'name',
                    label: this.$t('groups.create.name_placeholder') + '*',
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'color-picker',
                    property: 'color',
                    label:
                        this.$t('groups.headsets_groups_table_head.color') +
                        '*',
                    required: true,
                    inputProps: {
                        hideDetails: true,
                    },
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'user-selector',
                    property: 'visible_by',
                    label:
                        this.$t(
                            'groups.headsets_groups_table_head.gestionnaire'
                        ) + '*',
                    inputProps: {
                        company_id: companyId,
                        label: this.$t(
                            'groups.headsets_groups_table_head.select_gestionnaire'
                        ),
                        multiple: true,
                    },
                },
            ],
        },
    ]
}
