import ApiClient from '..'

export class GroupProvider {
    static get route() {
        return `/groups`
    }

    static findAll(query = null) {
        const api = ApiClient.getInstance()
        return api.get(this.route, query)
    }

    static getCompanyAllGroupsWithUsers(query) {
        const api = ApiClient.getInstance()
        query = { ...query, withUsers: true }
        return api.get(this.route, query)
    }

    static getCompanyUsersVisibleGroups(query) {
        const api = ApiClient.getInstance()
        query = { ...query, restricted: true}
        return api.get(this.route, query)
    }
    static getCompanyDevicesGroups(query) {
        const api = ApiClient.getInstance()
        query = { ...query, withDevices: true }
        return api.get(this.route, query)
    }

    static delete(id) {
        const api = ApiClient.getInstance()
        return api.delete(`${this.route}/${id}`)
    }

    static update(id, data) {
        const api = ApiClient.getInstance()
        return api.put(`${this.route}/${id}`, data)
    }

    static updateMultipleUsersGroupsDevices(data) {
        const api = ApiClient.getInstance()
        return api.post(`${this.route}/update-multiple-users-groups-devices`, data)
    }

    static create(data, query = {}) {
        const api = ApiClient.getInstance()
        return api.post(this.route, data, query)
    }
}
