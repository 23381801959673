<script>
    import GroupSelector from '@components/tools/GroupSelector.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    export default {
        components: { GroupSelector },
        mixins: [mixins.RaDialogMixin],
        props: {
            companyId: {
                type: Number,
                required: true,
            },
        },
        emits: ['onGroupSelected'],
        data() {
            return {
                group_id: null,
                loading: false,
            }
        },
        methods: {
            affect() {
                this.$emit('onGroupSelected', this.group_id, 'groupIds')
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            {{ $t('groups.affect.title') }}
        </template>
        <ra-stack gap="7">
            <group-selector
                v-model="group_id"
                item-value="id"
                :category="2"
                :company="companyId"
            />
            <ra-stack direction="horizontal">
                <ra-spacer />
                <v-btn
                    :loading="loading"
                    class="bg-primary"
                    @click="close"
                >
                    {{ $t('generics.cancel') }}
                </v-btn>
                <v-btn
                    :loading="loading"
                    class="bg-primary-gradient"
                    @click="affect"
                >
                    {{ $t('groups.affect.cta') }}
                </v-btn>
            </ra-stack>
        </ra-stack>
    </ra-base-dialog>
</template>

<style scoped lang="scss"></style>
