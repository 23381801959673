<script>
    import { LicenseCollection } from '@libs/Collections/LicenseCollection.js'
    import { LearnerStructure } from '@libs/Structures/LearnerStructure.js'
    import { learnerAddFormInputs } from '@components/Learners/LearnerAddForm.inputs.js'
    import { CompanyStructure } from '@libs/Structures/CompanyStructure.js'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { LearnerProvider } from '@providers/LearnerProvider.js'
    import { mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'

    export default {
        name: 'LearnerAddForm',
        mixins: [mixins.RaDialogMixin],
        props: {
            company: CompanyStructure,
            licenses: LicenseCollection,
            targetDisplayUser: Object,
        },
        data() {
            return {
                learner: LearnerStructure.getDefault(),
                error: null,
            }
        },
        computed: {
            ...mapState(useUserStore, ['user']),
            inputs() {
                return learnerAddFormInputs.call(
                    this,
                    this.learner,
                    this.targetDisplayUser,
                    this.company,
                    this.licenses
                )
            },
        },
        methods: {
            async handleSubmit() {
                if (this.targetDisplayUser) {
                    this.learner.user_id = this.targetDisplayUser.id
                } else if (this.user && this.user.id) {
                    this.learner.user_id = this.user.id
                }
                const { data } = await LearnerProvider.createLearner(
                    {
                        ...this.learner.json,
                       company_id: this.company.id
                    }
                )
                    .bindVue(this)
                    .call()

                this.$emit('added', data)
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            <ra-typo bold>{{ $t('learners.actions.add') }}</ra-typo>
        </template>
        <ra-form
            :element="learner"
            :error="error"
            :inputs="inputs"
            :autosubmit="false"
            :required="false"
            :footer="false"
        />
        <ra-stack
            direction="horizontal"
            alignment="center"
            margin="2,0"
        >
            <ra-typo
                color="primary"
                uppercase
                style="font-size: 10px; opacity: 0.7;"
                >{{ $t('learners.form.required') }}</ra-typo
            >
            <ra-spacer style="flex: 1 1 0" />
            <v-btn
                rounded="lg"
                variant="flat"
                @click="close"
                >{{ $t('learners.form.cancel') }}</v-btn
            >
            <v-btn
                rounded="lg"
                color="primary-gradient"
                @click="handleSubmit"
                >{{ $t('learners.form.add') }}</v-btn
            >
        </ra-stack>
    </ra-base-dialog>
</template>

<style lang="scss" scoped></style>
