<script>
    import { HeadsetStream } from '@libs/MDM/core/HeadsetStream.js'

    export default {
        props: {
            stream: {
                type: HeadsetStream,
                required: true,
            },
        },

        emits: ['togglePinned'],

        computed: {
            playIcon() {
                return this.stream.state?.pause ? 'fa-play' : 'fa-pause'
            },

            volumeIcon() {
                return this.stream.state?.sound
                    ? 'fa-volume-on'
                    : 'fa-volume-off'
            },

            micIcon() {
                return this.stream.state?.micro ? 'fa-mic' : 'fa-mic-off'
            },

            expandIcon() {
                return this.stream.state?.pinned ? 'fa-compress' : 'fa-expand'
            },
        },

        methods: {
            handlePin() {
                this.stream.togglePin()
                this.$emit('togglePinned', this.stream)
            },
        },
    }
</script>

<template>
    <ra-stack
        direction="horizontal"
        distribution="side-spacing"
        class="stream-player-controls"
    >
        <v-btn
            icon
            color="white"
            @click.stop="stream.togglePause()"
        >
            <ra-icon fill>{{ playIcon }}</ra-icon>
        </v-btn>

        <v-btn
            icon
            color="white"
            @click.stop="stream.toggleVolume()"
        >
            <ra-icon fill>{{ volumeIcon }}</ra-icon>
        </v-btn>

        <v-btn
            icon
            color="white"
            @click.stop="stream.toggleMicro()"
        >
            <ra-icon fill>{{ micIcon }}</ra-icon>
        </v-btn>

        <v-btn
            icon
            color="white"
            @click.stop="handlePin"
        >
            <ra-icon fill>{{ expandIcon }}</ra-icon>
        </v-btn>
    </ra-stack>
</template>

<style scoped lang="scss">
    .stream-player-controls {
        button {
            opacity: 0.8;
        }
        button:hover {
            opacity: 1;
        }
    }
</style>
