import { Structure } from '@libs/Structures/Structure.js'

export class GroupStructure extends Structure {
    constructor(...args) {
        super(...args)
    }
    set restricted(value) {
        this._restricted = value ? 1 : 0
    }

    get restricted() {
        return Boolean(this._restricted)
    }

    get json() {
        return {
            ...this,
            restricted: this._restricted,
        }
    }

    static getDefault(company_id) {
        return new this({
            name: '',
            color: '#1A4AD1',
            restricted: 0,
            visible_by: [],
            company_id,
        })
    }
}
