<script>
    import avatarChip from '@ui/AvatarChip.vue'
    export default {
        components: {
            avatarChip,
        },
        props: {
            user: {
                type: Object,
                default: undefined,
            },
        },
        data() {
            return {
                hasError: false,
            }
        },
    }
</script>
<template>
    <ra-box
        v-ripple
        centered-content
        class="account-container"
        padding=",3"
        v-bind="$attrs"
        data-test="user-account-activator"
    >
        <v-icon size="large">mdi-account-circle</v-icon>
        <ra-stack
            alignement="center"
            direction="horizontal"
            gap="5"
        >
            <ra-box>
                <ra-typo bold color="black" style="font-size: 14px;"> {{ user.identity }}</ra-typo>
                <v-chip
                    v-if="user.role.title === 'Administrateur'"
                    density="comfortable"
                    rounded="lg"
                    color="#ECF6FF"
                    variant="flat"
                    ><ra-typo style="color: #0a3b63; font-weight: 500;">Admin</ra-typo></v-chip
                >
            </ra-box>
            <avatar-chip
                :user="user"
                size="35"
            />
        </ra-stack>
    </ra-box>
</template>
<style lang=""></style>
