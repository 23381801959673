<script>
    import HeadsetGroupsPatchDialog from './HeadsetGroupsPatchDialog.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { useCurrentCompanyViewed } from '@/store/currentCompanyViewed'
    import { mapState } from 'pinia'
    import { GroupProvider } from '@/core/api/providers'
    import { GroupCollection } from '@/libs/Collections/GroupCollection'
    import { groupsHeadsetTableListHeaders } from './HeadsetGroupsTableList.headers'
    import { GroupStructure } from '@/libs/Structures/GroupStructure'
    import ArrayEllipsizer from '@components/tools/ArrayEllipsizer.vue'
    import TableView from '@layouts/global/TableView.vue'
    import { useGroupStore } from '@/store/groupStore'

    export default {
        components: { TableView, ArrayEllipsizer, HeadsetGroupsPatchDialog },
        mixins: [mixins.RaDialogsMixin],
        data() {
            return {
                items: new GroupCollection(),
                editGroup: null,
                timeout: null,
            }
        },
        computed: {
            ...mapState(useCurrentCompanyViewed, ['companyID']),
            actions() {
                return [
                    {
                        label: this.$t('groups.create_group'),
                        icon: 'mdi-plus',
                        handler: () => {
                            this.editGroup = null
                            this.openDialog('dialog-group-patch-form')
                        },
                    },
                ]
            },
            itemActions() {
                return [
                    {
                        label: this.$t('groups.create.edit_cta'),
                        icon: 'mdi-pencil',
                        handler: (el) => {
                            this.editGroup = new GroupStructure(el)
                            this.openDialog('dialog-group-patch-form')
                        },
                    },
                    {
                        label: this.$t('groups.delete'),
                        icon: 'mdi-trash-can-outline',
                        handler: (el) => {
                            GroupProvider.delete(el.id).call()
                            const groupStore = useGroupStore()
                            groupStore.fetch()
                            this.fetchGroups()
                        },
                    },
                ]
            },
            headers() {
                return groupsHeadsetTableListHeaders.call(this)
            },
        },
        created() {
            this.items.setFetcher((query) =>
                GroupProvider.getCompanyDevicesGroups({
                    company_id: this.companyID,
                    ...query,
                })
            )
        },
        methods: {
            async fetchGroups() {
                await this.items.fetchPaginated()
            },
        },
    }
</script>

<template>
    <table-view
        :actions="actions"
        :title="
            $tc('groups._table_list', Math.max(1, items.total), {
                count: items.total,
            })
        "
    >
        <ra-table
            v-model:items-per-page="items.perPage"
            :grouped-actions="groupedActions"
            :headers="headers"
            :item-actions="itemActions"
            :items="items"
            :items-length="items.total"
            :loading="items.loading"
            :no-data-text="$t('groups._no_group')"
            v-bind="$attrs"
            @update:options="fetchGroups($event)"
            @click:row="handleClick"
        >
            <template #item.name="{ item }">
                <v-chip :color="item.color" variant="flat">
                    <ra-stack color="black">
                        {{ item.name }}
                    </ra-stack>
                </v-chip>
            </template>
            <template #item.visible_by="{ item }">
                <array-ellipsizer
                    :max-length="2"
                    :items="item.visible_by"
                    property="identity"
                />
            </template>
            <template #item.devices="{ item }">
                <v-chip size="small">
                    <ra-typo>
                        {{ item.devices.length }}
                        <template
                            v-if="item.devices.length"
                            #tooltip
                        >
                            <ra-typo
                                v-for="device in item.devices"
                                :key="device.pretty_name"
                            >
                                {{ device.pretty_name }}
                            </ra-typo>
                        </template>
                    </ra-typo>
                </v-chip>
            </template>
            <template #item.stats>
                <ra-box centered-content>
                    <v-btn
                        icon
                        variant="plain"
                    >
                        <ra-icon>mdi-chart-bar</ra-icon>
                    </v-btn>
                </ra-box>
            </template>
        </ra-table>
        <ra-dialog
            ref="dialog-group-patch-form"
            @close="fetchGroups"
        >
            <headset-groups-patch-dialog
                :group="editGroup"
                :company-id="companyID"
                :title="
                    !editGroup
                        ? $t('groups.create.create_title')
                        : $t('groups.create.edit_cta')
                "
                :submit-text="
                    editGroup
                        ? $t('groups.create.edit_cta')
                        : $t('groups.create.create_cta')
                "
            />
        </ra-dialog>
    </table-view>
</template>

<style scoped lang="scss"></style>
