<script>
    import Callout from '@ui/Callout.vue'
    import { mapActions, mapState } from 'pinia'
    import { useGroupStore } from '@store/groupStore.js'
    import { Headset } from '@libs/MDM/core/Headset.js'
    import { HeadsetProvider } from '@providers/HeadsetProvider.js'
    import { useCurrentCompanyViewed } from '@store/currentCompanyViewed.js'
    import GroupSelector from '@components/tools/GroupSelector.vue'

    export default {
        components: { GroupSelector, Callout },
        props: {
            headset: {
                type: Headset,
                required: true,
            },
        },

        data() {
            return {
                edit: false,
            }
        },

        computed: {
            ...mapState(useGroupStore, ['groups']),
            ...mapState(useCurrentCompanyViewed, ['companyID']),
            users() {
                return this.group.visible_by
                    .map((user) => user.identity)
                    .join(', ')
            },

            group: {
                get() {
                    return this.headset?.state?.groups?.[0] ?? null
                },
                set(value) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.headset.state.groups[0] = value
                },
            },
        },
        mounted() {
            this.getGroups().then(console.log)
        },
        methods: {
            ...mapActions(useGroupStore, ['getGroups']),
            toggleEdit() {
                this.edit = !this.edit
            },
            async handleSaveGroups() {
                this.edit = false
                await HeadsetProvider.update(this.companyID, this.headset.id, {
                    groups_ids: [this.group.id],
                }).call()
            },
        },
    }
</script>

<template>
    <ra-stack
        padding="0,6"
        gap="4"
    >
        <ra-card>
            <template #title>
                {{ $tc('groups._name.label1', 1) }}
            </template>
            <template #right>
                <ra-icon
                    v-if="!edit"
                    weight="bold"
                    class="clickable"
                    @click="toggleEdit"
                >
                    fa-edit
                </ra-icon>
                <ra-typo
                    v-else
                    span
                    underline
                    class="clickable"
                    @click="toggleEdit"
                    >{{ $t('generics.cancel') }}</ra-typo
                >
            </template>
            <ra-stack padding="3">
                <template v-if="!edit">
                    <ra-typo>
                        <v-chip
                            v-if="group"
                            :color="group.color"
                            >{{ group.name }}</v-chip
                        >
                        <v-chip v-else>{{ $t('groups._no_group') }}</v-chip>
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            span
                            semi
                            >{{ $t('groups.users') }}:</ra-typo
                        >
                        {{ users }}
                    </ra-typo>
                    <ra-typo>
                        <ra-typo
                            span
                            semi
                        >
                            {{ $t('groups.users_connected_by_default') }}:
                        </ra-typo>
                        {{ users }}
                    </ra-typo>
                </template>

                <template v-else>
                    <group-selector
                        v-model="group"
                        :company="companyID"
                        :category="2"
                        return-object
                    />
                    <Callout bg="primary-5-alpha">{{
                        $t('groups.headset_group_callout')
                    }}</Callout>
                    <ra-stack direction="horizontal">
                        <ra-spacer />
                        <v-btn
                            variant="flat"
                            color="primary-gradient"
                            @click="handleSaveGroups"
                        >
                            {{ $t('generics.save') }}
                        </v-btn>
                    </ra-stack>
                </template>
            </ra-stack>
        </ra-card>
    </ra-stack>
</template>

<style scoped lang="scss"></style>
