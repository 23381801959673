<script>
    import { useLanguageStore } from '@store/lang.js'
    import { mapActions, mapState } from 'pinia'
    import LocaleEditDialog from '@components/global/Languages/LocaleEditDialog.vue'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { useUserStore } from '@store/user.js'
    import { useAppStore } from '@store/app.js'

    export default {
        components: { LocaleEditDialog },
        props: [
            'modelValue',
            'nofield',
            'toTranslate',
            'isInline',
            'dark',
            'noEdit',
        ],
        mixins: [mixins.RaDialogsMixin],
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            ...mapState(useLanguageStore, ['locales']),
            ...mapState(useUserStore, ['user', 'isGod']),
            ...mapState(useAppStore, ['isTablet']),
            lang: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                },
            },
            langIndex: {
                get() {
                    return this.locales.findIndex((l) => l.code == this.lang)
                },
                set(value) {
                    this.lang = this.locales[value].code
                },
            },
        },
        watch: {
            addLocaleDialog: function () {
                if (this.addLocaleDialog) {
                    this.languagesMutable = [...this.languages]
                }
            },
            languages: {
                handler: function () {
                    if (this.addLocaleDialog) {
                        this.languagesMutable = [...this.languages]
                    }
                },
                deep: true,
            },
        },
    }
</script>
<template>
    <ra-dialog ref="dialog-locale-edit">
        <locale-edit-dialog :locales="locales" />
    </ra-dialog>
    <div>
        <div v-if="!isInline">
            <v-select
                v-model="lang"
                :items="locales"
                :style="{
                    color: dark ? 'primary' : 'white',
                }"
                class="custom-select"
                item-title="name"
                item-value="code"
                variant="outlined"
            >
                <template v-slot:selection="{ item, index }">
                    <ra-stack
                        direction="horizontal"
                        distribution="fill"
                        class="selection-language"
                    >
                        <v-icon>mdi-web</v-icon>
                        <span>{{ isTablet ? item.raw.code : item.title }}</span>
                    </ra-stack>
                </template>
                <template
                    v-if="isGod && !noEdit"
                    v-slot:append-item
                >
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item @click="openDialog('dialog-locale-edit')">
                        <ra-stack direction="horizontal">
                            <v-icon> mdi-plus</v-icon>
                            <ra-typo> Ajouter une langue</ra-typo>
                        </ra-stack>
                    </v-list-item>
                </template>
            </v-select>
        </div>
        <div
            v-else
            class="no-shadow"
        >
            <v-tabs
                v-model="langIndex"
                align-tabs="center"
                background-color="transparent"
            >
                <v-tab
                    v-for="(l, i) in locales"
                    :key="i"
                    :data-test="'lang-select-' + l.code"
                    >{{ l.name }}</v-tab
                >
            </v-tabs>
        </div>
    </div>
</template>
<style lang="scss">
    .no-shadow {
        & .v-tabs {
            box-shadow: none !important;
        }
        & .v-tabs .v-tabs-bar__content {
            box-shadow: none !important;
        }

        & .v-tab {
            font-size: 0.8em;
            padding: 0 0.5em;
            min-width: unset;
            margin: 0 0.5em;
            text-transform: none !important;

            & .v-ripple__container {
                display: none;
            }

            &:hover::before,
            &:focus::before {
                opacity: 0 !important;
            }
        }

        & .v-tabs-bar {
            height: 1.5em;
        }

        & .v-tabs-slider {
            height: 50% !important;
        }
    }

    .custom-select {
        & .v-input__slot {
            margin-bottom: 0 !important;
        }

        & .v-input__details {
            display: none;
        }

        & .v-field {
            border-radius: 50px !important;
        }

        & span {
            text-transform: uppercase;
            font-weight: 600;
        }

        .v-field__outline {
            --v-field-border-width: 2px;
        }

        .v-field__outline__start {
            flex: 0 0 32px;
        }

        .selection-language {
            padding: 4px 0;
        }
    }

    [data-device='tablet'] {
        .custom-select {
            .v-field__outline {
                display: none;
            }
            .v-icon {
                display: none;
            }
        }
    }
</style>
