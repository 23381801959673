<script>
    import { NavigationDrawerContent } from '@components/global/NavigationDrawer/NavigationDrawerContent.js'
    import { mapState } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'
    import { DevRoutesNames } from '@routes/God/Dev.names.js'
    import { useGlobalEnv } from '@store/globalEnv.js'
    import { CompaniesRoutesName } from '@routes/God/Companies.names.js'
    import StorageStatusBar from '@components/Developers/StorageStatus/StorageStatus.vue'

    export default {
        name: 'NavigationDrawer',
        components: { StorageStatusBar, GlobalLanguageSelector },
        computed: {
            content() {
                return new NavigationDrawerContent(
                    this.user,
                    this.globalEnv,
                    this.$router
                )
            },
            CompaniesRoutesName() {
                return CompaniesRoutesName
            },
            DevRoutesNames() {
                return DevRoutesNames
            },
            ...mapState(useUserStore, ['user']),
            ...mapState(useGlobalEnv, ['globalEnv']),
            links() {
                if (!this.user) return []
                return this.content.getLinks()
            },
        },
        methods: {
            isActiveLink(link) {
                let isActive = link.activeIf
                    ? link.activeIf(this.$route)
                    : this.$route.path.startsWith(link.url)
                return isActive
            },
        },
    }
</script>

<template>
    <ra-stack
        class="main-drawer"
        gap="0"
    >
        <ra-box
            centered-content
            class="logo"
            padding=",6"
        >
            <img
                alt="logo"
                src="@assets/images/logos/logo_full.png"
            />
        </ra-box>
        <ra-stack
            v-if="user"
            bg="primary-gradient"
            class="navigation-drawer"
            tag="nav"
        >
            <ra-box>
                <ra-stack
                    v-for="link of links"
                    :key="link.url"
                    v-ripple
                    :class="
                        isActiveLink(link) ? 'router-link-exact-active' : ''
                    "
                    :to="link.url"
                    alignement="center"
                    direction="horizontal"
                    gap="4"
                    padding="4"
                    tag="router-link"
                    data-test="navigation-drawer-link"
                >
                    <ra-icon
                        fill
                        :size="20"
                        >{{ link.icon }}</ra-icon
                    >
                    <ra-typo uppercase>
                        {{ $t(link.label) }}
                    </ra-typo>
                </ra-stack>
            </ra-box>
            <ra-spacer fluid />
            <ra-box
                v-if="content.isDev && user.isGod"
                padding="4,4,0,4"
            >
                <StorageStatusBar />
            </ra-box>
            <ra-box
                v-if="user && user.type >= 1 && user.type <= 3"
                padding="4,4,0,4"
            >
                <v-btn
                    :to="'/contact'"
                    class="bg-rose footer-drawer-btn"
                    elevation="0"
                >
                    <ra-typo
                        color="white"
                        semi
                        tag="div"
                        uppercase
                    >
                        {{ $t('support.contact_us') }}
                    </ra-typo>
                </v-btn>
            </ra-box>
            <ra-stack padding="0,4,4,4">
                <global-language-selector />
                <router-link
                    v-if="user.isGod"
                    :to="{
                        name: $route.path.startsWith('/dev')
                            ? CompaniesRoutesName.index
                            : DevRoutesNames.distributor.index,
                    }"
                >
                    <ra-typo>
                        {{
                            $route.path.startsWith('/dev')
                                ? 'Go to main platform'
                                : 'Go to dev platform'
                        }}
                    </ra-typo>
                </router-link>
            </ra-stack>
        </ra-stack>
    </ra-stack>
</template>

<style lang="scss" scoped>
    .bg-rose {
        height: unset !important;
    }

    .footer-drawer-btn {
        border-radius: 50px;
        padding: 14px;
        width: 100%;
    }
    .main-drawer {
        height: 100%;

        .logo {
            flex: 0 0 61px;
            border-right: 1px rgba(grey, 0.2) solid;

            img {
                width: 100%;
                text-align: center;
                height: auto;
            }
        }
    }

    .navigation-drawer {
        flex: 1 1 100%;

        &.bg-primary-gradient {
            background: linear-gradient(
                180deg,
                var(--secondary),
                var(--third)
            ) !important;
            color: white !important;
        }
        .router-link-exact-active {
            position: relative;
            background: rgba(255, 255, 255, 0.1);
            &:before {
                width: 0.2em;
                height: 50%;
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: white;
                border-radius: 0 8px 8px 0;
            }
        }
        .ra-stack {
            &:not(.router-link-exact-active) {
                color: rgba(white, 0.5);
                font-weight: 400;
            }

            font-weight: 600;
            border-bottom: 1px rgba(white, 0.3) solid;
        }
    }
</style>
