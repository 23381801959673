export function groupsHeadsetTableListHeaders() {
    return [
        {
            text: this.$t('groups.headsets_groups_table_head.name'),
            value: 'name',
        },
        {
            text: this.$t('groups.headsets_groups_table_head.gestionnaire'),
            value: 'visible_by',
        },
        {
            text: this.$t('groups.headsets_groups_table_head.linked_headsets'),
            value: 'devices',
        },
        {
            text: this.$t('groups.headsets_groups_table_head.stats'),
            value: 'stats',
        },
    ]
}
