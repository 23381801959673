<script>
    import { GroupStructure } from '@/libs/Structures/GroupStructure'
    import { headsetGroupPatchInputs } from './HeadsetGroupsPatch.inputs'
    import { GroupProvider } from '@/core/api/providers'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { useGroupStore } from '@/store/groupStore'

    export default {
        mixins: [mixins.RaDialogMixin],
        props: {
            group: {
                type: GroupStructure,
                required: false,
                default: () => null,
            },

            title: {
                type: String,
                required: true,
            },

            submitText: {
                type: String,
                required: true,
            },

            companyId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                groupValue: GroupStructure.getDefault(this.companyId),
                errors: {},
            }
        },

        computed: {
            edit() {
                return Boolean(this.group)
            },

            inputs() {
                return headsetGroupPatchInputs.call(this, this.companyId)
            },
        },

        mounted() {
            this.groupValue = this.group ? this.group.clone() : this.groupValue
            this.groupValue.restricted = true
        },

        methods: {
            async handleSubmit() {
                const provider = this.edit
                    ? GroupProvider.update.bind(GroupProvider)
                    : GroupProvider.create.bind(GroupProvider)
                const args = this.edit
                    ? [this.groupValue.id, this.groupValue.json, { type: 'devices' }]
                    : [this.groupValue.json, { type: 'devices' }]

                const groupStore = useGroupStore()

                try {
                    await provider(...args)
                        .bindVue(this)
                        .call()

                    groupStore.fetch()
                    this.close()
                } catch (e) {
                    console.error(e)
                }
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>{{ title }}</template>
        <ra-form
            :inputs="inputs"
            :element="groupValue"
            :error="{ errors }"
            :submit-text="submitText"
            @submit="handleSubmit"
        ></ra-form>
    </ra-base-dialog>
</template>
