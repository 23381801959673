import { GroupCollection } from '@libs/Collections/GroupCollection'
import { defineStore } from 'pinia'
export const useGroupStore = defineStore('group', {
    state: () => ({
        fetcher: null,
        groups: new GroupCollection(),
    }),

    actions: {
        async fetch() {
            if (this.fetcher) {
                await this.groups.fetchPaginated()
            } else throw new Error('Fetcher is not defined')
        },

        init(fetcher) {
            this.fetcher = fetcher
            this.groups = new GroupCollection()
            this.groups.setFetcher(fetcher)
        },

        async getGroups(fetcher) {
            if (this.fetcher == null) {
                this.init(fetcher)
            }
            if (this.groups.lastFetchedAt === null) {
                await this.fetch()
            }
            return this.groups
        },

        reset() {
            this.fetcher = null
            this.groups = new GroupCollection()
        },
    },
})
