<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true,
            },
            maxLength: {
                type: Number,
                required: false,
                default: 3,
            },
            property: {
                type: String,
                required: false,
                default: '$OBJECT',
            },
        },

        methods: {
            getPropertyValue(item) {
                return this.property === '$OBJECT' ? item : item[this.property]
            },
        },
    }
</script>

<template>
    <ra-typo>
        <template v-for="(item, index) in items">
            <slot
                name="item"
                v-bind="{ item, index }"
            >
                {{ getPropertyValue(item) }}
            </slot>
            <template v-if="index < maxLength">
                <template
                    v-if="index < maxLength - 1 && index !== items.length - 1"
                    >,
                </template>
                <template v-else-if="items.length > maxLength">
                    + {{ items.length - maxLength }}
                </template>
            </template>
        </template>
    </ra-typo>
</template>

<style scoped lang="scss"></style>
