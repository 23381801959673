<script>
    import Checkbox from '@ui/UiCheckbox.vue'
    import { HeadsetFormationsCollection } from '@libs/Collections/HeadsetFormationsCollection.js'
    import Callout from '@ui/Callout.vue'
    import { HeadsetSettingSession } from '@libs/MDM/core/Entries/HeadsetSettingSession.js'

    export const FORMATION_FILTERS = {
        RA: 'ra',
        CUSTOM: 'custom',
    }

    export default {
        components: { Callout, Checkbox },
        props: {
            formations: {
                type: HeadsetFormationsCollection,
                required: true,
            },
            settings: {
                type: HeadsetSettingSession,
                required: true,
            },
            edit: {
                type: Boolean,
                default: false,
            },
            editOnly: {
                type: Boolean,
                default: false,
            },
            column: {
                type: Number,
                default: 1,
            },
        },
        emits: ['update:edit', 'update'],
        data() {
            return {
                filterFormation: FORMATION_FILTERS.RA,
                selectAll: false,
            }
        },

        computed: {
            selectedAll: {
                get() {
                    return this.formations.every(
                        (formation) => formation.activated
                    )
                },
                set(val) {
                    if (val) {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.settings.activatedFormations = Array.from(
                            this.formations.map((formation) => formation.slug)
                        )
                    } else {
                        // eslint-disable-next-line vue/no-mutating-props
                        this.settings.activatedFormations = []
                    }
                    this.$emit('update')
                },
            },
            packs() {
                if (this.filterFormation === FORMATION_FILTERS.RA) {
                    return this.formations.notCustoms().groupByPack()
                } else {
                    return this.formations.customs().groupByPack()
                }
            },
            valueEdit: {
                get() {
                    return this.edit
                },
                set(val) {
                    if (this.editOnly) {
                        return
                    }
                    this.$emit('update:edit', val)
                },
            },
        },
        methods: {
            toggleEdit() {
                this.valueEdit = !this.valueEdit
            },
            toggleActivatedFormation(formationSlug) {
                const temp = this.settings.activatedFormations || []
                if (temp.includes(formationSlug)) {
                    temp.splice(temp.indexOf(formationSlug), 1)
                } else {
                    temp.push(formationSlug)
                }
                // eslint-disable-next-line vue/no-mutating-props
                this.settings.activatedFormations = temp
                this.$emit('update')
            },
        },
    }
</script>

<template>
    <ra-stack gap="6">
        <slot name="formation-library-start"></slot>

        <Callout>
            {{ $t('headsets.formations_library.callout') }}
        </Callout>

        <ra-stack
            direction="horizontal"
            alignement="center"
            distribution="between"
        >
            <ra-box
                flex="0 1 350px"
                :style
            >
                <ra-tab-select
                    v-model="filterFormation"
                    density="comfortable"
                    :options="[
                        {
                            label: $t(
                                'headsets.formations_library.filter.formation_ra'
                            ),
                            value: 'ra',
                        },
                        {
                            label: $t(
                                'headsets.formations_library.filter.formation_custom'
                            ),
                            value: 'custom',
                        },
                    ]"
                />
            </ra-box>
            <ra-typo
                v-if="!editOnly"
                underline
                class="clickable text-right"
                @click="toggleEdit"
            >
                {{ !edit ? $t('generics.edit') : $t('generics.cancel') }}
            </ra-typo>
            <ra-typo
                v-else
                class="text-right"
            >
                <ra-checkbox
                    v-model="selectedAll"
                    hide-details
                    :label="$t('generics.select.select_all')"
                />
            </ra-typo>
        </ra-stack>
        <ra-box
            class="pack-grid"
            :style="{
                '--gridColNum': column,
            }"
        >
            <ra-card
                v-for="(pack, i) in packs"
                :key="i"
                :style="{
                    ...packs.getPackStructure(i).gradientCSSVars,
                }"
                class="pack-card"
            >
                <template #title>
                    {{ pack.name }}
                </template>
                <template #right>
                    <Checkbox
                        v-if="edit"
                        checked
                        size="25"
                    />
                </template>
                <ra-stack
                    padding="4"
                    gap="0"
                >
                    <ra-box
                        v-for="formation in pack.formations"
                        :key="formation.id"
                        class="clickable"
                        @click="() => toggleActivatedFormation(formation.slug)"
                    >
                        <ra-checkbox
                            v-if="edit"
                            class="no-event"
                            hide-details
                            :label="formation.name"
                            readonly
                            :model-value="formation.activated"
                        />
                        <ra-typo
                            v-else
                            :style="{
                                opacity: formation.activated ? 1 : 0.5,
                            }"
                            @click.stop
                        >
                            {{ formation.name }}
                        </ra-typo>
                    </ra-box>
                </ra-stack>
            </ra-card>

            <ra-typo v-if="!packs.length">
                {{ $t('headsets.formations_library.no_formation') }}
            </ra-typo>
        </ra-box>
    </ra-stack>
</template>
<style scoped lang="scss">
    .pack-grid {
        display: grid;
        grid-template-columns: repeat(var(--gridColNum), 1fr);
        gap: 24px;
    }
</style>
<style lang="scss">
    .pack-card {
        .ra-card-new-header {
            color: white;
            background: linear-gradient(
                90deg,
                var(--gradient-left-color) 0%,
                var(--gradient-right-color) 100%
            );
        }
        box-shadow: 0 2px 5px 0 rgba(27, 36, 44, 0.2) !important;
    }
</style>
