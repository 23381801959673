export function learnerAddFormInputs(
    learner,
    targetDisplayUser,
    company,
    licenses
) {
    return [
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'lastname',
                    label: this.$t('learners.form.lastname'),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'firstname',
                    label: this.$t('learners.form.firstname'),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'email',
                    label: this.$t('learners.form.email'),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'formation-selector',
                    property: 'formation_ids',
                    label: this.$t('learners.form.formations.label'),
                    inputProps: {
                        items: licenses.formations,
                        multiple: true,
                        label: this.$t('learners.form.formations.placeholder'),
                    },
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'group-selector',
                    label: this.$t('learners.form.groups'),
                    property: 'groups',
                    inputProps: {
                        company_id: company.id,
                    },
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-label',
                    content: this.$t('learners.form.authorize_access'),
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'switch',
                    property: 'is_vr',
                    label: this.$t('learners.properties.is_vr.label3'),
                    inputProps: {

                        hint: false,
                        density: 'compact',
                    },
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'switch',
                    property: 'is_elearning',
                    label: this.$t('learners.properties.is_elearning.label3'),
                    inputProps: {
                        hint: false,
                        density: 'compact',
                    },
                },
            ],
        },
        {
            type: 'form-row',
            density: 'dense',
            elements: [
                {
                    is: 'form-input',
                    type: 'switch',
                    property: 'notify',
                    label: this.$t('learners.properties.platform_access.label'),
                    tooltip: this.$t(
                        'learners.properties.platform_access.info'
                    ),
                    inputProps: {
                        hint: false,
                        density: 'compact',
                        tooltipOptions: {
                            location: 'top start',
                            width: 300,
                        },
                    },
                },
            ],
        },
    ]
}
