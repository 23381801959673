<script>
    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import Loader from '@components/tools/Loader.vue'
    import WorkshopInscription from '@components/Workshops/WorkshopInscription.vue'
    import WorkshopShareMenu from '@/components/Workshops/WorkshopShareMenu.vue'
    import { AUTH_TYPE, useUserStore } from '@store/user.js'
    import { useWorkshopStore } from '@store/workshop'
    import { mapActions, mapState } from 'pinia'

    export default {
        name: 'MyWorkshopsPublicIndexPage',
        components: {
            ApplicationLayout,
            Loader,
            WorkshopInscription,
            WorkshopShareMenu,
        },
        mounted() {
            // true parameter can show the page without login
            this.loadWorkshop(this.$route.params.workshop_id, true)
        },
        computed: {
            ...mapState(useWorkshopStore, ['workshopValue', 'workshopLoaded']),
            AUTH_TYPE() {
                return AUTH_TYPE
            },
            ...mapState(useUserStore, {
                userType: (state) => state.type,
            }),
        },
        methods: {
            ...mapActions(useWorkshopStore, [
                'setWorkshop',
                'loadWorkshop',
                'switchWorkshop',
            ]),
            openShare() {
                this.openDialog('dialog-share-formation')
            },
        },
    }
</script>

<template>
    <ra-stack padding="10">
        <loader :wait-for="workshopLoaded">
            <ra-box v-if="workshopValue.name">
                <ra-heading-title
                    :title="
                        $tc('workshops._name', 1) + ' ' + workshopValue.name
                    "
                >
                    <template #actions>
                        <ra-stack
                            direction="horizontal"
                            flex="0 1 150px"
                        >
                            <workshop-share-menu />
                        </ra-stack>
                    </template>
                </ra-heading-title>
                <ra-stack>
                    <ra-stack
                        mobile-swap
                        direction="horizontal"
                        distribution="fill-equally"
                    >
                        <ra-card>
                            <ra-stack>
                                <ra-typo>
                                    <v-icon
                                        :color="workshopValue.status.color"
                                        class="mr-2"
                                        size="x-small"
                                    >
                                        mdi-circle
                                    </v-icon>
                                    {{ $t(workshopValue.status.text) }}
                                </ra-typo>
                                <img
                                    v-if="
                                        workshopValue.company &&
                                        workshopValue.company.logo
                                    "
                                    :src="workshopValue.company?.logo"
                                />
                                <ra-typo
                                    h3
                                    v-else
                                    >{{ workshopValue.company?.name }}</ra-typo
                                >
                                <div>
                                    <ra-typo
                                        bold
                                        padding="1,0"
                                        >{{
                                            $filters.formatDate(
                                                workshopValue.date,
                                                'dddd DD MMMM YYYY'
                                            )
                                        }}</ra-typo
                                    >
                                    <ra-typo padding="1,0">
                                        {{
                                            $filters.formatTime(
                                                workshopValue.start_time
                                            )
                                        }}
                                        -
                                        {{
                                            $filters.formatTime(
                                                workshopValue.end_time
                                            )
                                        }}
                                        •
                                        {{
                                            workshopValue.allow_inscription
                                                ? 'Avec inscriptions'
                                                : 'Sans inscriptions'
                                        }}
                                    </ra-typo>
                                    <ra-typo>{{
                                        workshopValue.address
                                    }}</ra-typo>
                                </div>
                            </ra-stack>
                        </ra-card>
                        <ra-card padding="2">
                            <ra-stack
                                class="cardinscription"
                                direction="horizontal"
                                gap="6"
                                alignement="center"
                            >
                                <ra-box>
                                    <ra-icon
                                        class="giant-icon"
                                        color="primary-gradient"
                                        >fa-users</ra-icon
                                    ></ra-box
                                >
                                <ra-stack>
                                    <ra-typo
                                        size="x-large"
                                        bold
                                        >{{
                                            workshopValue.nb_apprenants
                                        }}</ra-typo
                                    >
                                    <ra-typo size="large"
                                        >Apprenants inscrits</ra-typo
                                    >
                                </ra-stack>
                            </ra-stack>
                        </ra-card>
                        <ra-card class="former-card">
                            <template #title>Votre Formateur</template>
                            <ra-stack
                                height="100%"
                                direction="horizontal"
                                alignement="center"
                            >
                                <v-avatar
                                    :src="workshopValue.formateur?.avatar"
                                    :alt="workshopValue.formateur?.name"
                                ></v-avatar>
                                <ra-stack gap="0">
                                    <ra-typo bold
                                        >{{
                                            workshopValue.formateur?.firstname
                                        }}
                                        <ra-typo
                                            uppercase
                                            span
                                        >
                                            {{
                                                workshopValue.formateur
                                                    ?.lastname
                                            }}</ra-typo
                                        ></ra-typo
                                    >
                                    <ra-typo>{{
                                        workshopValue.formateur?.email
                                    }}</ra-typo>
                                </ra-stack>
                            </ra-stack>
                        </ra-card>
                    </ra-stack>
                    <ra-stack
                        direction="horizontal"
                        mobile-swap
                    >
                        <ra-stack flex="1 1 60%">
                            <workshop-inscription
                                v-if="workshopValue.allow_inscription"
                                :workshop="workshopValue"
                                :slots="workshopValue.slots"
                            />
                            <ra-card>
                                <template #title>{{
                                    $t('workshop.workshop_program')
                                }}</template>
                                <ra-stack padding="4,0,0,0">
                                    <ra-card
                                        variant="outlined"
                                        v-for="formation in workshopValue.formations"
                                        :key="formation.id"
                                    >
                                        <ra-stack
                                            distribution="side-spacing"
                                            direction="horizontal"
                                            mobile-swap
                                        >
                                            <img :src="formation.image_id" />
                                            <ra-stack flex="1 1 70%">
                                                <ra-typo h3>{{
                                                    formation.title
                                                }}</ra-typo>
                                                <ra-typo span
                                                    >5 modules . 20
                                                    minutes</ra-typo
                                                >
                                                <ra-typo
                                                    p
                                                    size="x-small"
                                                    >{{
                                                        formation.description
                                                    }}</ra-typo
                                                >
                                            </ra-stack>
                                            <ra-stack flex="1 1 10%">
                                                <v-btn
                                                    variant="text"
                                                    size="x-small"
                                                    link
                                                >
                                                    Voir la fiche de
                                                    synthèse</v-btn
                                                >
                                                <v-btn
                                                    variant="text"
                                                    size="x-small"
                                                    >Voir le guide
                                                    pédagogique</v-btn
                                                >
                                                <v-btn
                                                    variant="text"
                                                    size="x-small"
                                                >
                                                    <v-icon
                                                        class="mr-2"
                                                        size="small"
                                                    >
                                                        mdi-download
                                                    </v-icon>
                                                    Télécharger le
                                                    certificat</v-btn
                                                >
                                            </ra-stack>
                                        </ra-stack>
                                    </ra-card>

                                    <ra-card variant="outlined">
                                        <ra-typo h3
                                            >Enquête de satisfaction</ra-typo
                                        >
                                        <ra-typo
                                            >Récoltez les retours des
                                            participants en partageant l'enquête
                                            de satisfaction</ra-typo
                                        >
                                    </ra-card></ra-stack
                                >
                            </ra-card>
                        </ra-stack>
                        <ra-stack
                            flex="1 1 40%"
                            v-if="
                                workshopValue.description ||
                                workshopValue.documents.length
                            "
                        >
                            <ra-card v-if="workshopValue.description">
                                <template #title>{{
                                    $t('workshops.informations')
                                }}</template>
                                <ra-typo padding="5,0,0,0">{{
                                    workshopValue.description
                                }}</ra-typo>
                            </ra-card>
                            <ra-card v-if="workshopValue.documents.length">
                                <template #title>{{
                                    $t('workshops.documents')
                                }}</template>
                                <ul>
                                    <li
                                        class="document"
                                        v-for="document in workshopValue.documents"
                                        :key="document.id"
                                    >
                                        {{ document.original_name }}
                                    </li>
                                </ul>
                            </ra-card>
                        </ra-stack>
                    </ra-stack>
                </ra-stack>
            </ra-box>
            <ra-box>
                <ra-typo
                    size="large"
                    bold
                    center
                    >{{ $t('workshops.no_workshop_available') }}</ra-typo
                >
            </ra-box>
        </loader>
    </ra-stack>
</template>

<style lang="scss" scoped>
    .giant-icon {
        font-size: 5rem;

        background: var(--primary-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .former-card {
        display: flex;
        flex-direction: column;
        .v-card-text {
            padding: 0;
        }
    }
    .document {
        padding: 5px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
    }
    .cardinscription {
        min-height: 120px;
    }
</style>
