<script>
    import { mapActions, mapState } from 'pinia'
    import { useGroupStore } from '@store/groupStore.js'
    import { GroupProvider } from '@api/providers/index.js'

    export const GROUP_CATEGORY = {
        USER: 1,
        HEADSET: 2,
    }
    export default {
        name: 'GroupSelector',
        props: {
            company: {
                type: Number,
                default: null,
            },
            category: {
                type: Number,
                default: GROUP_CATEGORY.USER,
            },
            modelValue: {
                default: null,
                type: Number,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            forceReload: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue', 'groups:loaded'],

        computed: {
            ...mapState(useGroupStore, ['groups']),
            value: {
                get() {
                    return this.modelValue
                },
                set(val) {
                    this.$emit('update:modelValue', val)
                },
            },
        },
        created() {
            this.init()
        },
        methods: {
            ...mapActions(useGroupStore, ['getGroups', 'reset']),
            async init() {
                if (this.forceReload) {
                    this.reset()
                }
                await this.getGroups((query) => {
                    return this.category === GROUP_CATEGORY.USER
                        ? GroupProvider.getCompanyAllGroupsWithUsers({
                              ...query,
                              company_id: this.company,
                          })
                        : GroupProvider.getCompanyDevicesGroups({
                              ...query,
                              company_id: this.company,
                          })
                })
                this.$emit('groups:loaded', Array.from(this.groups))
            },
        },
    }
</script>

<template>
    <ra-input name="group">
        <v-autocomplete
            v-model="value"
            density="compact"
            :items="groups"
            :loading="groups.loading"
            variant="outlined"
            :label="$tc('groups._name.label1', multiple ? 2 : 1)"
            item-title="name"
            :multiple="multiple"
            hide-details
            v-bind="$attrs"
        >
            <template #selection="{ item, index }">
                <slot
                    name="selection"
                    v-bind="{ item, index }"
                >
                    <v-chip
                        v-if="index < 2"
                        :color="item.raw.color"
                    >
                        <span>{{ item.title }}</span>
                    </v-chip>
                    <span
                        v-if="index === 2"
                        class="text-primary-lighten-6 text-caption align-self-center"
                    >
                        (+{{ value.length - 2 }} others)
                    </span>
                </slot>
            </template>
            <slot></slot>
        </v-autocomplete>
    </ra-input>
</template>

<style scoped lang="scss"></style>
