<script>
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import { formerFormInputs } from '@components/Formers/FormerForm.inputs.js'
    import { FormersProvider } from '@providers'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import withUser from '@mixins/withUser.js'

    export default {
        name: 'FormerAddForm',
        mixins: [mixins.RaDialogMixin, withUser],
        props: {
            company: {
                type: Object,
                required: true,
            },
        },
        emits: ['added'],
        data() {
            return {
                translationAlias: new TranslationAlias('formers', this),
                former: null,
                error: null,
            }
        },
        computed: {
            inputs() {
                return formerFormInputs.call(this, this.company, false)
            },
        },
        created() {
            this.reset()
        },
        methods: {
            reset() {
                this.former = {
                    type: 1,
                    is_beta: false,
                    is_demo: false,
                    admin: false,
                    is_vr: null,
                    is_elearning: null,
                    firstname: '',
                    lastname: '',
                    email: '',
                    notify: true
                }
            },
            async handleSubmit() {
                if(this.company.is_vr){
                    this.former.is_vr = true
                }
                if(this.company.is_elearning){
                    this.former.is_elearning = true
                }
                await FormersProvider.createFormer(this.company.id, this.former)
                    .bindVue(this)
                    .call()
                this.$emit('added')
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            <ra-typo bold>{{ $_t('actions.add') }}</ra-typo>
        </template>
        <ra-form
            :element="former"
            :error="error"
            :inputs="inputs"
            :autosubmit="false"
            :required="false"
            :footer="false"
        >
            <template #end>
                <ra-stack
                    direction="horizontal"
                    alignment="center"
                    margin="5,0,0,0"
                >
                    <ra-typo
                        color="primary"
                        uppercase
                        style="font-size: 10px; opacity: 0.7;"
                        >{{ $t('formers.form.required') }}</ra-typo
                    >
                    <ra-spacer style="flex: 1 1 0" />
                    <v-btn
                        rounded="lg"
                        variant="flat"
                        @click="close"
                        >{{ $t('formers.form.cancel') }}</v-btn
                    >
                    <v-btn
                        rounded="lg"
                        color="primary-gradient"
                        @click="handleSubmit"
                        >{{ $t('formers.form.add') }}</v-btn
                    >
                </ra-stack>
            </template>
        </ra-form>
    </ra-base-dialog>
</template>
